var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email ? item.email : "-")+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone ? item.phone : "-")+" ")]}},{key:"item.founder",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isFounder() ? _vm.$t("data_table.yes") : _vm.$t("data_table.no"))+" ")]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isAdmin() ? _vm.$t("data_table.yes") : _vm.$t("data_table.no"))+" ")]}},{key:"item.teacher",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isTeacher() ? _vm.$t("data_table.yes") : _vm.$t("data_table.no"))+" ")]}},{key:"item.authorized_person",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isAuthorizedPerson() ? _vm.$t("data_table.yes") : _vm.$t("data_table.no"))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? _vm.$t("data_table.yes") : _vm.$t("data_table.no"))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }