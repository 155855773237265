









import UsersTable from "@/components/users/table/UsersTable.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Users",
  components: { UsersTable, DefaultLayout },
});
