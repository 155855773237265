




















import { UserDetailResponse } from "@/api/user/types/Responses";
import UserClient from "@/api/user/UserClient";
import PageTitle from "@/components/layout/PageTitle.vue";
import UserDetail from "@/components/users/misc/UserDetail.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { Identity } from "@/utils/Identity";
import _ from "lodash";
import Vue from "vue";

export default Vue.extend({
  name: "User",
  components: { UserDetail, PageTitle, DefaultLayout },
  data: () => ({
    // loading
    isBusyUser: false,
    // data
    user: null as UserDetailResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyUser;
    },
    userId(): number {
      return _.toInteger(this.$route.params.userId);
    },
  },
  methods: {
    fetchUser(loading = true): void {
      if (loading) {
        this.isBusyUser = true;
      }
      UserClient.getUser(this.userId)
        .then((response) => {
          this.user = response;
        })
        .finally(() => {
          if (loading) {
            this.isBusyUser = false;
          }
        });
    },
    onLoginAs(): void {
      Identity.onChangeIdentity(this.userId)
        .then((response) => {
          // snackbar
          this.$snackbarSuccess(this.$t("layout.identity_changed"));
          // go to user's dashboard
          this.$router.push({ name: "dashboard" });
          return response;
        });
    },
  },
  created(): void {
    this.fetchUser();
  },
});
